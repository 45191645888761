export const isPassiveSupport = () => {
  let passiveSupported = false;

  try {
    const options = {
      get passive() {
        // This function will be called when the browser
        // attempts to access the passive property.
        passiveSupported = true;
        return false;
      },
    };
    const noop = () => {};
    window.addEventListener('mouseup', noop, options);
    window.removeEventListener('mouseup', noop);
  } catch (err) {
    passiveSupported = false;
  }
  return passiveSupported;
};
