import * as Sentry from '@sentry/react';
import type { SeverityLevel } from '@sentry/react';
export function useLogging() {
  return function log(
    message: string | Record<string, unknown>,
    severity: SeverityLevel = 'log'
  ): void {
    try {
      if (typeof Sentry !== 'undefined') {
        Sentry.captureMessage(JSON.stringify(message), severity);
      } else {
        switch (severity) {
          case 'error':
          case 'fatal':
            console.error(message);
            break;
          case 'warning':
            console.warn(message);
            break;
          case 'log':
          case 'info':
          default:
            console.log(message);
            break;
        }
      }
    } catch {
      /** :shrug: */
    }
  };
}
