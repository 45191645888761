import { ROUTE_COMPANY, ROUTE_COMPANY_WITH_EVENTS } from '@/constants/routes';
import { useRewardfulData } from '@/features/rewardful';
import { getLocation } from '@/redux/location/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLogin } from '@/hooks/useLogin';
import { useTracking } from 'react-tracking';
import { useJWTDecoder } from '@/hooks/useJWTDecoder';
import { useHotjar } from '@/hooks/useHotjar';

export function useOAuthButtons({
  onLoginSuccess,
  onRegisterSuccess,
}: {
  onLoginSuccess?: () => void;
  onRegisterSuccess?: () => void;
}) {
  const location = useSelector(getLocation);
  const dispatch = useDispatch();
  const { decode } = useJWTDecoder();
  const [hotjar] = useHotjar();
  const onSuccess = useCallback<
    NonNullable<Parameters<typeof useLogin>[0]['onSuccess']>
  >(
    (response) => {
      try {
        const decoded = decode(response.data.access_token);
        if (decoded) {
          hotjar?.('identify', decoded.sub, {
            register_date: new Date().toISOString(),
          });
        }
      } catch (err) {
        console.error('decoding error', err);
      }
      if (response.data.created) {
        dispatch({
          type: 'UI__START_ONBOARDING',
          payload: {
            registeredOnCompanyPage: [
              ROUTE_COMPANY,
              ROUTE_COMPANY_WITH_EVENTS,
            ].includes(location.type),
          },
        });
        onRegisterSuccess?.();
      } else {
        onLoginSuccess?.();
      }
    },
    [dispatch, location.type, onLoginSuccess, onRegisterSuccess, hotjar, decode]
  );

  const { trackEvent } = useTracking({
    type: 'track',
  });

  const {
    mutate: login,
    status,
    error,
  } = useLogin({
    onSuccess,
    onError() {
      trackEvent({ action: 'failure', modifier: 'login-oauth' });
    },
  });
  const { referralData } = useRewardfulData();

  const handleGoogle = useCallback(
    (token: string) => login({ type: 'Google', token, referralData }),
    [login, referralData]
  );
  const handleFacebook = useCallback(
    (token: string) => login({ type: 'Facebook', token, referralData }),
    [login, referralData]
  );
  const handleApple = useCallback(
    (
      token: string,
      user?: {
        email: string;
        name: {
          firstName: null | string;
          lastName: null | string;
        };
      }
    ) => login({ type: 'Apple', token, user, referralData }),
    [login, referralData]
  );
  return {
    status,
    error,
    handleGoogle,
    handleFacebook,
    handleApple,
  };
}
