import { useState, useEffect } from 'react';
import loadable from '@loadable/component';

const Switcher =
  RUNTIME_ENV === 'native'
    ? loadable(() => import('./AppleNative'), {
        resolveComponent: (components) => components.AppleNative,
      })
    : loadable(() => import('./AppleWeb'), {
        resolveComponent: (components) => components.AppleWeb,
      });

interface Props {
  onSuccess: (
    token: string,
    user?: {
      email: string;
      name: {
        firstName: null | string;
        lastName: null | string;
      };
    }
  ) => void;
  disabled?: boolean;
}

export const Apple = (props: Props) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (RUNTIME_ENV === 'native') {
      async function checkPlatform() {
        const { Device } = await import('@capacitor/device');
        const response = await Device.getInfo();
        if (response.platform === 'ios') {
          /** hide apple sign in if android sorry */
          setEnabled(true);
        }
      }
      checkPlatform();
    } else {
      // show on desktop
      setEnabled(true);
    }
  }, []);

  if (enabled) {
    return <Switcher {...props} />;
  }

  return null;
};
