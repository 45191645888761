import { useCallback } from 'react';
import Tooltip from '@/components/Tooltip';
import { useApple, ButtonState } from '../../hooks';
import { ContinueWithAppleButton, AppleIconWhite } from './styled';
import {
  WarningDescription,
  HitboxFull,
} from '@/components/OAuthButtons/styled';
import { useTranslation } from '@/hooks/useTranslation';

interface AppleSigninSuccess {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

interface Props {
  disabled?: boolean;
  onError?: (error: string) => void;
  onSuccess: (token: string, user: AppleSigninSuccess['user']) => void;
  showBtnText?: boolean;
}

export function ContinueWithApple({
  onSuccess,
  onError,
  disabled,
  showBtnText = true,
}: Props) {
  const { warning, submitState, login } = useApple();

  const handleClick = useCallback(() => {
    login(onSuccess, onError);
  }, [login, onSuccess, onError]);
  const t = useTranslation();
  return (
    <>
      {warning !== '' && (
        <Tooltip
          light
          title={<WarningDescription>{warning}</WarningDescription>}
          openDelay={300}
          zIndex={99999}
        >
          <HitboxFull />
        </Tooltip>
      )}
      <ContinueWithAppleButton
        display="block"
        styleType="loudLarge"
        disabled={disabled || submitState !== ButtonState.IDLE}
        onClick={handleClick}
        data-cy-id="button-apple-login"
        isLoading={submitState === ButtonState.LOADING}
      >
        <AppleIconWhite />
        {showBtnText && t('app.modal.registration.continueWithApple.label')}
      </ContinueWithAppleButton>
    </>
  );
}
