import {
  isValidElement,
  cloneElement,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import type { ReactNode, ReactElement, SyntheticEvent } from 'react';

import Popper from '@/components/Tooltip/components/Popper';
import type { Placement } from '@popperjs/core';
import { useTheme } from '@simplywallst/ui-core';
import { isPassiveSupport } from '@utilities/passiveSupport';
export interface Props {
  title: ReactNode;
  children: ReactElement;
  placement?: Placement;
  light?: boolean;
  zIndex?: number;
  offsetX?: number;
  offsetY?: number;
  openDelay?: number;
  closeDelay?: number;
  anchorEl?: Element | null;
  onOpen?: (event?: SyntheticEvent) => void;
  onClose?: (event?: SyntheticEvent) => void;
  initialOpen?: boolean;
}

type EventListenerType = 'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onBlur';

const Tooltip = ({
  children,
  title,
  zIndex,
  offsetX,
  anchorEl,
  onOpen,
  onClose,
  placement = 'top',
  light = false,
  openDelay = 100,
  closeDelay = 100,
  offsetY = 8,
  initialOpen = false,
}: Props) => {
  const safeChild =
    isValidElement(children) || typeof children !== 'string' ? (
      children
    ) : (
      <span>{children}</span>
    );

  // Hooks
  const theme = useTheme();
  const safeChildRef = (safeChild as any).ref;
  const startYRef = useRef(0);
  const ref = useRef<HTMLElement | null>(
    (safeChildRef && safeChildRef.current) || null
  );
  const delay = useRef(0);
  const [open, setOpen] = useState(initialOpen);
  const [childNode, setChildNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (ref) {
      const { current } = ref;
      current && setChildNode(current);
    }
  }, [ref]);

  const closeWithDelay = useCallback(() => {
    clearInterval(delay.current);
    setTimeout(() => {
      setOpen(false);
    }, closeDelay);
  }, [closeDelay]);

  const openWithDelay = useCallback(() => {
    delay.current = window.setTimeout(() => setOpen(true), openDelay);
  }, [openDelay]);

  const handleClose = useCallback(
    (e?: SyntheticEvent, eventListenerType?: EventListenerType) => {
      if (eventListenerType) {
        //  Forward event
        const eventListener = safeChild.props[eventListenerType];
        eventListener && eventListener(e);
      }
      //
      onClose && onClose(e);
      //
      closeWithDelay();
    },
    [closeWithDelay, onClose, safeChild.props]
  );

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      const { current } = ref;
      if (current && document.activeElement === current) {
        const touch = e.changedTouches[0];
        startYRef.current = touch.pageY;
      }
    },
    [startYRef, ref]
  );

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      const { current } = ref;
      if (current) {
        const touch = e.changedTouches[0];
        if (
          Math.abs(touch.pageY - startYRef.current) < 1 &&
          document.activeElement === current &&
          typeof SVGElement.prototype.blur !== 'undefined'
        ) {
          current.blur();
        } else if (open && touch.target !== current) {
          const closest = (touch.target as HTMLElement).closest(
            current.nodeName
          );
          if (closest !== current) {
            handleClose(undefined, 'onMouseLeave');
          }
        }
      }
    },
    [handleClose, open]
  );

  const handleOpen = useCallback(
    (e?: SyntheticEvent, eventListenerType?: EventListenerType) => {
      if (eventListenerType) {
        //  Forward event
        const eventListener = safeChild.props[eventListenerType];
        eventListener && eventListener(e);
      }
      //
      if (e && e.currentTarget.hasAttribute('title')) {
        e.currentTarget.removeAttribute('title');
      }

      onOpen && onOpen(e);
      openWithDelay();
    },
    [onOpen, openWithDelay, safeChild.props]
  );

  const handleFocus = useCallback(
    (e: SyntheticEvent) => {
      handleOpen(e, 'onFocus');
    },
    [handleOpen]
  );
  const handleBlur = useCallback(
    (e: SyntheticEvent) => {
      handleClose(e, 'onBlur');
    },
    [handleClose]
  );

  useEffect(() => {
    const options = isPassiveSupport() ? { passive: true } : false;
    document.addEventListener('keyup', handleKeyUp, options);
    document.addEventListener('touchstart', handleTouchStart, options);
    document.addEventListener('touchend', handleTouchEnd, options);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchEnd, handleTouchStart]);

  //
  if (title === null) return children;

  // Events

  const handleMouseEnter = (e: SyntheticEvent) => handleOpen(e, 'onMouseEnter');
  const handleMouseLeave = (e: SyntheticEvent) =>
    handleClose(e, 'onMouseLeave');

  const handleMouseDown = (e?: SyntheticEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    //  Forward event
    const onMouseDown = safeChild.props.onMouseDown;
    onMouseDown && onMouseDown(e);
    //  Set element to being focused
    const { current } = ref;
    if (current && document.activeElement !== current && !open) {
      current.focus();
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    const { current } = ref;
    if (current) {
      switch (e.keyCode) {
        case 27:
          typeof SVGElement.prototype.blur !== 'undefined' && current.blur();
          break;
        default:
          // noop
          break;
      }
    }
  };

  const childProps = {
    ref,
    'aria-describedby': open ? 'tooltipDescription' : null,
    tabIndex: 0,
    // Child eventListners can be overwritten as they'll be fowarded via the functions below
    ...safeChild.props,
    // Updated mobile eventListners
    onFocus: handleFocus,
    onBlur: handleBlur,
    onMouseDown: handleMouseDown,
  };

  // Apply mouse events enter/leave only for desktop to stop them calling over the top of focus/blur
  // Do this only on non-ssr

  if (
    RUNTIME_ENV !== 'server' &&
    (window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth) > theme.mdMaxRaw
  ) {
    childProps.onMouseEnter = handleMouseEnter;
    childProps.onMouseLeave = handleMouseLeave;
  } else if (RUNTIME_ENV !== 'server') {
    childProps.onTouchStart = handleMouseEnter;
    childProps.onTouchEnd = handleTouchEnd;
  }

  return (
    <>
      {cloneElement(safeChild, childProps)}
      <Popper
        anchorEl={anchorEl || childNode}
        light={light}
        open={open}
        offsetX={offsetX}
        offsetY={offsetY}
        placement={placement}
        zIndex={zIndex}
      >
        {typeof title === 'string' ? <span>{title}</span> : title}
      </Popper>
    </>
  );
};

export default Tooltip;
