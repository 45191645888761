import GoogleIcon from '@assets/images/icons/vendor/g-icon.svg';
import { SocialButton, WarningDescription, Hitbox } from '../../styled';
import { LoadingSpinner } from '@simplywallst/ui-core';
import { useGoogleIdentity } from '../useGoogleIdentity';
import Tooltip from '@/components/Tooltip';

interface Props {
  onSuccess: (token: string) => void;
}

export const GoogleWeb = ({ onSuccess }: Props) => {
  const { status, error, requestAccessToken } = useGoogleIdentity({
    onResolve: onSuccess,
  });

  return (
    <>
      {status === 'error' ? (
        <Tooltip
          light
          title={<WarningDescription>{error.message}</WarningDescription>}
          openDelay={300}
          zIndex={99999}
        >
          <Hitbox />
        </Tooltip>
      ) : null}
      <SocialButton
        disabled={status === 'loading'}
        onClick={requestAccessToken}
        data-cy-id="button-google-login"
      >
        {status === 'loading' ? <LoadingSpinner /> : <GoogleIcon />}
      </SocialButton>
    </>
  );
};
