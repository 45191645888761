import { useCallback } from 'react';
import {
  SocialButton,
  WarningDescription,
  Hitbox,
} from '@/components/OAuthButtons/styled';
import { AppleIcon } from './styled';
import { LoadingSpinner } from '@simplywallst/ui-core';
import Tooltip from '@/components/Tooltip';
import { useApple } from '../../hooks';

enum ButtonState {
  IDLE,
  DISABLED,
  LOADING,
}

interface AppleSigninSuccess {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

interface Props {
  disabled?: boolean;
  onError?: (error: string) => void;
  onSuccess: (token: string, user: AppleSigninSuccess['user']) => void;
}

export const AppleWeb = (props: Props) => {
  const { warning, submitState, login } = useApple();

  const handleClick = useCallback(() => {
    login(props.onSuccess, props.onError);
  }, [login, props.onSuccess, props.onError]);

  return (
    <>
      {warning !== '' && (
        <Tooltip
          light
          title={<WarningDescription>{warning}</WarningDescription>}
          openDelay={300}
          zIndex={99999}
        >
          <Hitbox />
        </Tooltip>
      )}
      <SocialButton
        disabled={props.disabled || submitState !== ButtonState.IDLE}
        onClick={handleClick}
        data-cy-id="button-apple-login"
      >
        {submitState === ButtonState.LOADING ? (
          <LoadingSpinner />
        ) : (
          <AppleIcon />
        )}
      </SocialButton>
    </>
  );
};
