import { useEffect, useState, useCallback } from 'react';
import { useScript, State } from '@/hooks/useScript';
import { useLogging } from '@/hooks/useLogging';
import { v4 } from 'uuid';
import { FB_APP_ID } from '@/constants/env';

export enum ButtonState {
  IDLE,
  DISABLED,
  LOADING,
  ERROR,
}

export function useFacebook() {
  const log = useLogging();
  const scriptState = useScript('https://connect.facebook.net/en_US/sdk.js');
  const [warning, setWarning] = useState('');
  const [submitState, setSubmitState] = useState<ButtonState>(
    ButtonState.LOADING
  );
  useEffect(() => {
    if (typeof window.FB === 'undefined') {
      (window as any).fbAsyncInit = function () {
        window.FB.init({
          appId: FB_APP_ID || '',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v9.0',
        });
        setSubmitState(ButtonState.IDLE);
      };
    } else {
      try {
        window.FB.init({
          appId: FB_APP_ID || '',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v9.0',
        });
        setSubmitState(ButtonState.IDLE);
      } catch (error: any) {
        log(error, 'error');
        setSubmitState(ButtonState.DISABLED);
        setWarning('Failed to initialise Facebook login');
      }
    }
    return () => {
      delete (window as any).fbAsyncInit;
    };
  }, []);

  useEffect(() => {
    switch (scriptState) {
      case State.ERROR:
        setSubmitState(ButtonState.DISABLED);
        setWarning('Unable to load script');
        break;
    }
  }, [scriptState]);

  const login = useCallback(
    (onSuccess: (token: string) => void) => {
      try {
        if (scriptState !== State.READY) return;
        setSubmitState(ButtonState.LOADING);
        window.FB.login(
          (response) => {
            switch (response.status) {
              case 'connected':
                if (typeof onSuccess === 'function') {
                  onSuccess(response.authResponse.accessToken);
                }
                setSubmitState(ButtonState.IDLE);
                break;
              default:
                setSubmitState(ButtonState.IDLE);
                break;
            }
          },
          {
            scope: 'public_profile,email',
          }
        );
      } catch (error: any) {
        log(error, 'error');
      }
    },
    [scriptState]
  );
  return {
    submitState,
    warning,
    login,
  };
}

interface AppleSigninSuccess {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

export function useApple() {
  const scriptState = useScript(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  );
  const [warning, setWarning] = useState('');
  const [submitState, setSubmitState] = useState<ButtonState>(ButtonState.IDLE);
  useEffect(() => {
    switch (scriptState) {
      case State.READY:
        (window as any).AppleID.auth.init({
          clientId: 'com.simplywallst.web',
          scope: 'name email',
          redirectURI: 'https://simplywall.st/welcome',
          state: v4(),
          usePopup: true,
        });
        setSubmitState(ButtonState.IDLE);
        break;
      case State.ERROR:
        setSubmitState(ButtonState.DISABLED);
        setWarning('An error occurred');
        break;
    }
  }, [scriptState]);

  const login = useCallback(
    async (
      onSuccess: (token: string, user: AppleSigninSuccess['user']) => void,
      onError?: (error: string) => void
    ) => {
      try {
        if (scriptState !== State.READY) return;
        setSubmitState(ButtonState.LOADING);
        const result: AppleSigninSuccess = await (
          window as any
        ).AppleID.auth.signIn();
        onSuccess(result.authorization.id_token, result.user);
        setSubmitState(ButtonState.IDLE);
      } catch (error: any) {
        setSubmitState(ButtonState.IDLE);
        if (
          typeof error.response !== 'undefined' &&
          typeof onError === 'function'
        ) {
          onError(error.response.data.error_description);
        }
      }
    },
    [scriptState]
  );

  return {
    login,
    warning,
    submitState,
  };
}
