import styled from 'styled-components';
import { FullButton } from '../../styled';
import EnvelopeSvg from '@assets/images/icons/legacy/envelope.svg';

export const EmailIcon = styled(EnvelopeSvg)`
  path {
    fill: white;
  }
`;

export const ContinueWithEmailButton = styled(FullButton)`
  background-color: ${({ theme, isLoading }) =>
    isLoading ? theme.color.mediumGray : theme.color.brand01};
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.brand01RGBA(0.9)};
  }
  &:active {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.brand01RGBA(0.8)};
  }
  &:disabled {
    color: ${({ theme }) => theme.color.white};
  }

  > svg {
    fill: ${(props) => props.theme.color.almostBlackRGBA(0.5)};
    width: 20px;
    height: 20px;
  }
`;
