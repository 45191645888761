import styled, { css } from 'styled-components';
import type { TransitionStatus } from 'react-transition-group/Transition';
import type { Placement } from '@popperjs/core';

interface PopperWrapperProps {
  light?: boolean;
  transitionState?: TransitionStatus;
}

interface PopperElementProps {
  zIndex?: number;
  blockPointerEvents?: boolean;
}

export const PopperElement = styled('div')<PopperElementProps>`
  pointer-events: ${({ blockPointerEvents }) =>
    blockPointerEvents ? 'none' : 'auto'};
  z-index: ${({ zIndex }) => zIndex || 0};
`;

export const Wrapper = styled('div')<PopperWrapperProps>`
  color: ${({ theme, light }) =>
    light ? theme.color.black : theme.color.white};
  background: ${({ theme, light }) =>
    light ? theme.color.white : theme.color.black};
  padding: ${({ theme }) => theme.x1spacePx}px;
  border-radius: 3px;
  box-shadow: ${({ theme }) => `0px 8px 12px ${theme.color.blackRGBA(0.4)}`};
  ${({ theme }) => theme.typography.tiny};
`;

interface TransitionWrapperProps {
  transitionState?: TransitionStatus;
  duration?: number;
}

export const TransitionWrapper = styled('div')<TransitionWrapperProps>`
  ${({ transitionState, duration }) => {
    const transitionStyle = `opacity ${duration}ms linear, transform ${duration}ms ease-out`;
    let opacity = 0;
    switch (transitionState) {
      case 'entering':
      case 'entered':
        opacity = 1;
        break;
      case 'exited':
      case 'exiting':
        opacity = 0;
        break;
    }
    return css`
      transition: ${transitionStyle};
      opacity: ${opacity};
    `;
  }};
`;

interface CaretProps {
  flipped?: boolean;
  light?: boolean;
  placement?: Placement;
}

export const Caret = styled('div')<CaretProps>`
  border: solid transparent;
  content: ' ';
  pointer-events: none;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-width: 8px;
  ${({ theme, flipped, light }) => {
    const borderColor = light ? theme.color.white : theme.color.black;
    if (flipped) {
      return css`
        bottom: 100%;
        border-bottom-color: ${borderColor};
      `;
    } else {
      return css`
        top: 100%;
        border-top-color: ${borderColor};
      `;
    }
  }}
`;
