import { useScript, State } from '@/hooks/useScript';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GOOGLE_CLIENT_ID } from '@/constants/env';

interface Config {
  onResolve: (token: string) => void;
}
export function useGoogleIdentity({ onResolve }: Config) {
  const [status, setStatus] = useState<'loading' | 'ready'>('loading');

  const [error, setError] =
    useState<google.accounts.oauth2.ClientConfigError>();

  const scriptState = useScript('https://accounts.google.com/gsi/client', {
    crossOrigin: undefined,
  });

  const googleTokenClient = useRef<
    google.accounts.oauth2.TokenClient | undefined
  >(undefined);

  const handleTokenCallback = useCallback(
    (response: google.accounts.oauth2.TokenResponse) => {
      onResolve(response.access_token);
    },
    [onResolve]
  );

  const handleTokenError = useCallback(
    (error: google.accounts.oauth2.ClientConfigError) => {
      if (error.type === 'popup_closed') {
        return;
      }
      setError(error);
    },
    []
  );

  useEffect(() => {
    switch (scriptState) {
      case State.ERROR:
        setError({
          name: 'script',
          type: 'unknown',
          message: 'Failed to load Google OAuth',
        });
        break;
      case State.READY:
        try {
          googleTokenClient.current = google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope:
              'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid',
            callback: handleTokenCallback,
            error_callback: handleTokenError,
          });
          setStatus('ready');
        } catch (error) {
          setError({
            name: 'error',
            type: 'unknown',
            message: 'An unknown error has occurred',
          });
        }
        break;
    }
  }, [scriptState, handleTokenCallback, handleTokenError]);

  const requestAccessToken = useCallback(() => {
    googleTokenClient.current?.requestAccessToken();
  }, []);

  if (typeof error !== 'undefined') {
    return { status: 'error', error } as const;
  }

  if (status === 'ready') {
    return { status, requestAccessToken };
  }

  return { status };
}
