import loadable from '@loadable/component';

const Switcher =
  RUNTIME_ENV === 'native'
    ? loadable(() => import('./GoogleNative'), {
        resolveComponent: (components) => components.GoogleNative,
      })
    : loadable(() => import('./GoogleWeb'), {
        resolveComponent: (components) => components.GoogleWeb,
      });

interface Props {
  onSuccess: (token: string) => void;
  disabled?: boolean;
}

export const Google = (props: Props) => <Switcher {...props} />;
