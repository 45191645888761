import loadable from '@loadable/component';

const Switcher =
  RUNTIME_ENV === 'native'
    ? loadable(() => import('./FacebookNative'), {
        resolveComponent: (components) => components.FacebookNative,
      })
    : loadable(() => import('./FacebookWeb'), {
        resolveComponent: (components) => components.FacebookWeb,
      });

interface Props {
  onSuccess: (token: string) => void;
  disabled?: boolean;
}

export const Facebook = (props: Props) => <Switcher {...props} />;
