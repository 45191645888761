import styled from 'styled-components';
import { FullButton } from '@/components/OAuthButtons/styled';

export const ContinueWithGoogleButton = styled(FullButton)`
  background-color: ${({ theme, isLoading }) =>
    isLoading ? theme.color.mediumGray : theme.color.google};
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.googleRGBA(0.9)};
  }
  &:active {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.googleRGBA(0.8)};
  }
  &:disabled {
    color: ${({ theme }) => theme.color.white};
  }
`;
