import { useCallback } from 'react';

type DecodedToken = {
  aud: string;
  exp: number;
  iat: number;
  id: string;
  iss: string;
  jti: string;
  kid: string;
  scope: string;
  sub: string;
  token_type: string;
};

/**
 * Custom React hook to decode JWT tokens.
 * @returns An object containing the `decode` function.
 */
export function useJWTDecoder<
  T extends Record<string, string | number> = DecodedToken,
>() {
  /**
   * Decode a JWT token.
   * @param {string} token - The JWT token to decode.
   * @returns {DecodedToken | null} The decoded token, or null if decoding fails.
   */
  const decode = useCallback((token: string): T | null => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(
            (char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2)
          )
          .join('')
      );
      const decodedToken = JSON.parse(jsonPayload);
      return decodedToken;
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return null;
    }
  }, []);

  return {
    decode,
  };
}
