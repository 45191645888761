import { useCallback, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { PopperElement, Wrapper, TransitionWrapper, Caret } from './styled';
import type { Instance, Placement, Options } from '@popperjs/core';
import {
  createPopperLite as createPopper,
  offset,
  arrow,
  flip,
  preventOverflow,
} from '@popperjs/core';
import { Portal } from '@simplywallst/ui-core';
import { Transition } from 'react-transition-group';

interface Props {
  anchorEl: Element | null;
  light?: boolean;
  offsetX?: number;
  offsetY?: number;
  open: boolean;
  placement?: Placement;
  tip?: string;
  zIndex?: number;
  role?: string;
  wrapper?: (
    children: ReactNode,
    props: Omit<Props, 'wrapper'>,
    isFlipped: boolean
  ) => ReactNode;
  animationDuration?: number;
  blockPointerEvents?: boolean;
  children: ReactNode;
}

const Popper = (props: Props) => {
  const { wrapper, ...propsWithoutWrapper } = props;
  // hooks
  const popperRef = useRef<Instance | null>(null);
  const [isFlipped, setFlipped] = useState(false);
  const handlePopperRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        if (!props.open || props.anchorEl === null) {
          // need to bail out
          cleanUpPopper();
          return;
        }

        if (popperRef.current !== null) {
          popperRef.current.destroy();
          popperRef.current = null;
        }
        const options: Options = {
          placement: props.placement as Placement,
          strategy: 'fixed',
          modifiers: [
            arrow,
            preventOverflow,
            {
              ...offset,
              options: {
                offset: [props.offsetX, props.offsetY],
              },
            },
            {
              ...flip,
              options: {
                fallbackPlacements: ['bottom'],
              },
            },
            {
              name: 'onUpdate',
              enabled: true,
              phase: 'afterWrite',
              fn(args) {
                const { state } = args;
                const isFlipped = state.options.placement !== state.placement;
                setFlipped(isFlipped);
              },
            },
            {
              name: 'computeStyles',
              options: {
                adaptive: false,
              },
            } /** This fixes safari mobile. https://popper.js.org/docs/v2/modifiers/compute-styles/#adaptive */,
          ],
        };
        const popper = createPopper(props.anchorEl, node, options);
        popperRef.current = popper;
      } else {
        cleanUpPopper();
      }
    },
    [props.open, props.anchorEl, props.placement, props.offsetX, props.offsetY]
  );
  const cleanUpPopper = () => {
    if (popperRef.current !== null) {
      const popperObj = popperRef.current;
      popperObj.destroy();
      popperRef.current = null;
    }
  };

  if (!props.open) return null;

  return (
    <Portal portalId="popup-portal">
      <Transition
        in={props.open}
        timeout={props.animationDuration as number}
        unmountOnExit
        mountOnEnter
        appear
      >
        {(transitionState) => {
          return (
            <PopperElement
              role={props.role}
              zIndex={props.zIndex}
              ref={handlePopperRef}
              blockPointerEvents={props.blockPointerEvents}
            >
              <TransitionWrapper
                duration={props.animationDuration}
                transitionState={transitionState}
              >
                {typeof props.wrapper === 'function' &&
                  props.wrapper(props.children, propsWithoutWrapper, isFlipped)}
              </TransitionWrapper>
            </PopperElement>
          );
        }}
      </Transition>
    </Portal>
  );
};

Popper.defaultProps = {
  role: 'tooltip',
  animationDuration: 120,
  blockPointerEvents: true,
  wrapper: (
    children: ReactNode,
    props: Omit<Props, 'wrapper'>,
    isFlipped: boolean
  ) => (
    <>
      <Wrapper id="tooltipDescription" light={props.light}>
        {children}
      </Wrapper>
      <Caret flipped={isFlipped} light={props.light} data-popper-arrow />
    </>
  ),
};

export default Popper;
