import GIconWhiteSvg from '@assets/images/icons/vendor/g-icon-white.svg';
import Tooltip from '@/components/Tooltip';
import { ContinueWithGoogleButton } from './styled';
import { WarningDescription, HitboxFull } from '../../styled';
import { useGoogleIdentity } from '../useGoogleIdentity';
import { useTranslation } from '@/hooks/useTranslation';
interface Props {
  disabled?: boolean;
  onSuccess: (token: string) => void;
}

export const ContinueWithGoogle = ({ disabled, onSuccess }: Props) => {
  const { status, requestAccessToken, error } = useGoogleIdentity({
    onResolve: onSuccess,
  });
  const t = useTranslation();
  return (
    <>
      {status === 'error' && (
        <Tooltip
          light
          title={<WarningDescription>{error.message}</WarningDescription>}
          openDelay={300}
          zIndex={99999}
        >
          <HitboxFull />
        </Tooltip>
      )}
      <ContinueWithGoogleButton
        display="block"
        styleType="loudLarge"
        disabled={disabled || status === 'loading'}
        onClick={requestAccessToken}
        data-cy-id="button-google-login"
      >
        <GIconWhiteSvg />
        {t('app.modal.registration.continueWithGoogle.label')}
      </ContinueWithGoogleButton>
    </>
  );
};
