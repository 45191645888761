import styled, { css } from 'styled-components';
import { Button, media } from '@simplywallst/ui-core';

const baseHitbox = css`
  position: absolute;
  z-index: 1;
  cursor: not-allowed;
`;

export const Hitbox = styled('div')`
  ${baseHitbox};
  width: 40px;
  height: 40px;
  margin-left: 8px;
`;

export const HitboxFull = styled('div')`
  ${baseHitbox};
  width: 303px;
  height: 40px;
`;

export const WarningDescription = styled('span')`
  max-width: 320px;
  overflow: hidden;
  display: block;
`;

export const SocialButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.theme.x1spacePx}px;
  margin-right: ${(props) => props.theme.x1spacePx}px;
  width: 42px;
  height: 42px;
  > svg {
    left: 10px;
    top: 10px;
    width: 22px;
    height: 22px;
    opacity: 1;
  }
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 2px 3px ${(props) => props.theme.color.blackRGBA(0.1)};
  &:hover,
  &:focus {
    box-shadow: 0px 2px 3px ${(props) => props.theme.color.blackRGBA(0.2)};
  }
  &:disabled {
    background: ${({ theme }) => theme.color.paper};
  }
`;

export const FullButton = styled(Button)`
  ${(props) => props.theme.typography.small};
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.lgUp`
    justify-content: flex-start;
    padding-left: ${({ theme }) => theme.x4spacePx}px;
  `}
  > svg {
    opacity: 1;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    margin-left: 12px;
  }
  color: ${({ theme }) => theme.color.white};
  &:disabled {
    cursor: not-allowed;
  }
`;
