import { useCallback } from 'react';
import FacebookIconSVG from '@assets/images/icons/vendor/f-icon-square.svg';
import { SocialButton, WarningDescription, Hitbox } from '../../styled';
import { LoadingSpinner } from '@simplywallst/ui-core';
import Tooltip from '@components/Tooltip';
import { useFacebook, ButtonState } from '../../hooks';

interface Props {
  disabled?: boolean;
  onSuccess: (token: string) => void;
}
export const FacebookWeb = (props: Props) => {
  const { warning, submitState, login } = useFacebook();

  const handleClick = useCallback(() => {
    login(props.onSuccess);
  }, [login, props.onSuccess]);

  return (
    <>
      {warning !== '' && (
        <Tooltip
          light
          title={<WarningDescription>{warning}</WarningDescription>}
          openDelay={300}
          zIndex={99999}
        >
          <Hitbox />
        </Tooltip>
      )}
      <SocialButton
        disabled={props.disabled || submitState !== ButtonState.IDLE}
        onClick={handleClick}
        data-cy-id="button-facebook-login"
      >
        {submitState === ButtonState.LOADING ? (
          <LoadingSpinner />
        ) : (
          <FacebookIconSVG />
        )}
      </SocialButton>
    </>
  );
};
