import styled from 'styled-components';
import { FullButton } from '../../styled';

import AppleSvg from '@assets/images/icons/vendor/apple.svg';

export const AppleIconWhite = styled(AppleSvg)`
  & path {
    fill: white;
  }
`;

export const ContinueWithAppleButton = styled(FullButton)`
  background-color: ${({ theme, isLoading }) =>
    isLoading ? theme.color.mediumGray : theme.color.black};
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.blackRGBA(0.9)};
  }
  &:active {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.blackRGBA(0.8)};
  }
  &:disabled {
    color: ${({ theme }) => theme.color.white};
  }
`;
