import styled from 'styled-components';
import AppleSvg from '@assets/images/icons/vendor/apple.svg';

export const AppleIcon = styled(AppleSvg)`
  &&& {
    fill: black;
  }
`;

export const AppleIconWhite = styled(AppleSvg)`
  & path {
    fill: white;
  }
`;
