import { useTranslation } from '@/hooks/useTranslation';
import { ContinueWithEmailButton, EmailIcon } from './styled';

type Props = {
  onClick: () => void;
  showBtnText?: boolean;
};

export const ContinueWithEmail = ({ onClick, showBtnText = true }: Props) => {
  const t = useTranslation();
  return (
    <ContinueWithEmailButton
      styleType="loudLarge"
      display="block"
      onClick={onClick}
      data-cy-id="button-email-register"
    >
      <EmailIcon />
      {showBtnText && t('app.modal.registration.continueWithEmail.label')}
    </ContinueWithEmailButton>
  );
};
